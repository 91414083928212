import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

import {UserRight, UserRightCategory} from "../../../../../../shared/models/entities/user-right";
import {PagedResponse} from "../../../../../../shared/models/api/shared/paged/paged-response.interface";
import {CustomerSite} from "../../../../../../shared/models/entities/customer-site";
import {UserRole, UserRoleType} from "../../../../../../shared/models/entities/user-role";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../../../shared/services/api/user.service";
import {Title} from "@angular/platform-browser";
import {PagedRequest} from "../../../../../../shared/models/api/shared/paged/paged-request";
import {debounceTime} from "rxjs/operators";
import {InvoiceRequest, StatusInvoice} from "../../../../../../shared/models/entities/invoice-request";
import {InvoiceRequestsService} from "../../../../../../shared/services/api/invoice-requests.service";
import {Customer} from "../../../../../../shared/models/entities/customer";
import {NzTableQueryParams, NzTableSortOrder} from "ng-zorro-antd/table";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiErrorMessageUtil} from "../../../../../../shared/utils/api-error-message.util";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'laveo-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrl: './invoice-list.component.scss'
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  category = UserRightCategory.invoiceRequests;
  isLoading = true;
  actionLoading = false;
  datas?: PagedResponse<InvoiceRequest>;
  currentPage = 1;
  limit = 10;

  searchForm: UntypedFormGroup;
  checked: string[] = [];
  error?: Error;

  userCanReadClients = false;
  userCanReadStructures = false;
  userCanAdd = false;
  userCanEdit = false;
  userCanDelete = false;

  isAdmin = false;
  currentRole?: UserRole;

  private sort: { key: string; value: NzTableSortOrder }[] = [{key: 'name', value: 'ascend'}];
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly invoiceRequestService: InvoiceRequestsService,
    private readonly userService: UserService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly titleService: Title,
    private readonly message: NzMessageService,
    private modal: NzModalService,
  ) {}

  get description(): string {
    const desc = 'Gérez ici les factures. ';
    const total = this.datas?.metadata?.totalResults ?? 0;

    if (total > 1) {
      return desc + 'Il y a ' + total + ' factures.';
    }

    if (total > 0) {
      return desc + 'Il y a ' + total + ' facture.';
    }

    return desc + 'Aucune facture trouvée.';
  }

  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
    this.setForm();
    this.setSearchIfExist();
    this.loadData();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  loadData(): void {
    console.log("load data");
    let sortProperty: string | undefined;
    let sortType: 'ASC' | 'DESC' | undefined;

    const currentSort = this.sort.find(s => s.value);
    if (currentSort) {
      sortProperty = currentSort.key;
      sortType = currentSort.value === 'ascend' ? 'ASC' : 'DESC';
    }

    const search: string = this.searchForm.get('search')?.value;

    const parameters = new PagedRequest({
      page: this.currentPage,
      limit: this.limit,
      sortProperty,
      sortType,
      search: search?.toLowerCase()?.trim()
    });

    this.isLoading = true;
    this.error = undefined;

    const invoicesSubscription = this.invoiceRequestService.allInvoices(parameters).subscribe({
      next: data => {
        console.log("Data found :", data.data);
        this.datas = data.data;

        this.isLoading = data.loading;
      },
      error: error => {
        this.isLoading = false;
        console.error(error);
        this.error = error;
      }
    });

    this.subscriptions.push(invoicesSubscription);
  }

  setPage(event: NzTableQueryParams): void {
    const indexSame = !event.pageIndex || this.currentPage === event.pageIndex;
    const limitSame = this.limit === event.pageSize;
    let sortSame = true;
    for (const sortObject of event.sort) {
      const originalSort = this.sort.find(sortElement => sortElement.key === sortObject.key);
      if (originalSort?.value !== sortObject.value) {
        sortSame = false;
        break;
      }
    }

    if (indexSame && limitSame && sortSame) {
      return;
    }

    this.currentPage = event.pageIndex;
    this.limit = event.pageSize;
    this.sort = event.sort;
    this.loadData();
  }

  setChecked(check: boolean, id: string): void {
    if (check && !this.checked.includes(id)) {
      this.checked.push(id);
    } else if (!check && this.checked.includes(id)) {
      this.checked = this.checked.filter(c => c !== id);
    }
  }

  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }

  goToCustomer(fromCustomer: Customer): void {
    void this.router.navigate(['/', 'admin', 'customers', fromCustomer.id]);
  }

  goToCustomerSite(fromCustomerSite: CustomerSite): void {
    void this.router.navigate(['/', 'admin', 'customer-sites', fromCustomerSite.id]);
  }

  delete(invoiceRequest: InvoiceRequest): void {
    this.modal.confirm({
      nzTitle: 'Suppression de la facture <b>' + invoiceRequest.reference + '</b>',
      nzContent: 'Êtes-vous sûr de vouloir supprimer la facture <b>' + invoiceRequest.reference + '</b>?<br /><br />' +
        'Les prestations associées seront marquées comme non facturées, vous devrez rééditer les factures des prestations associées à celle-ci.<br /><br />' +
        'Cette opération est irréversible, êtes-vous sûr(e) de vouloir continuer ?',
      nzOkText: 'Supprimer',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Annuler',
      nzOnOk: () => {


        this.invoiceRequestService.delete(invoiceRequest.id).subscribe({
          next: () => {

            setTimeout(() => {
              // Delai de reload (au cas ou)
              this.loadData();
            }, 200);
          },
          error: error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });

      }
    });
  }


  private setForm(): void {
    this.searchForm = this.formBuilder.group({
      search: this.formBuilder.control(null)
    });

    const searchSubscription = this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(formValues => {
      this.currentPage = 1;
      let queryParameters = {s: null};
      if (formValues.search && formValues.search !== '') {
        queryParameters = {s: formValues.search};
      }

      void this.router.navigate([], {queryParams: queryParameters, queryParamsHandling: 'merge'});
      this.loadData();
    });
    this.subscriptions.push(searchSubscription);
  }

  private setSearchIfExist(): void {
    const querySubscription = this.route.queryParamMap.subscribe(parameters => {
      const search = parameters.get('s');
      if (search) {
        this.searchForm.get('search')?.setValue(search);
      }
      this.loadData();
    });
    this.subscriptions.push(querySubscription);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.userCanReadClients = role.rights.customers.includes(UserRight.read);
      this.userCanReadStructures = role.rights.structures.includes(UserRight.read);
      this.userCanAdd = role.rights.customerSites.includes(UserRight.create);
      this.userCanEdit = role.rights.customerSites.includes(UserRight.update);
      this.userCanDelete = role.rights.customerSites.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des factures');
  }

  // Open the invoice PDF
  openPdf(invoiceRequest: InvoiceRequest) {
    if (invoiceRequest.pennylaneFileUrl) {
      window.open(invoiceRequest.pennylaneFileUrl, '_blank');
    }
  }

  isEditable(invoiceRequest: InvoiceRequest) {
    return invoiceRequest.status == "CREATED" || invoiceRequest.status == "VALIDATION_REQUIRED";
  }

  isDeletable(invoiceRequest: InvoiceRequest) {
    return this.userCanDelete && (invoiceRequest.status !== StatusInvoice.invoiced && invoiceRequest.status !== StatusInvoice.paid);

  }
}
