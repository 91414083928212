{
  "name": "laveo-web",
  "version": "2.0.0-SNAPSHOT",
  "scripts": {
    "graphql": "get-graphql-schema   http://localhost:8080/graphql  > schema.graphql",
    "ng": "ng",
    "start": "ng serve --disable-host-check",
    "build-local": "ng build ",
    "build-prod": "ng build --configuration production",
    "build-preview": "ng build --configuration preview",
    "lint": "ng lint && npx stylelint \"src/**/*.scss\" -f verbose"
  },
  "main": "src/main.ts",
  "private": true,
  "dependencies": {
    "@angular-magic/ngx-gp-autocomplete": "^2.0.2",
    "@angular/animations": "^17.2.4",
    "@angular/cdk": "^17.2.2",
    "@angular/common": "^17.2.4",
    "@angular/compiler": "^17.2.4",
    "@angular/core": "^17.2.4",
    "@angular/flex-layout": "15.0.0-beta.42",
    "@angular/forms": "^17.2.4",
    "@angular/google-maps": "^17.2.2",
    "@angular/platform-browser": "^17.2.4",
    "@angular/platform-browser-dynamic": "^17.2.4",
    "@angular/router": "^17.2.4",
    "@ant-design/icons-angular": "^17.0.0",
    "@apollo/client": "^3.9.11",
    "@fragaria/address-formatter": "^5.3.0",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@microsoft/signalr": "^8.0.7",
    "@sentry/angular-ivy": "^7.112.2",
    "apollo-angular": "^6.0.0",
    "apollo-cache-inmemory": "^1.6.6",
    "apollo-client": "^2.6.10",
    "apollo-link": "^1.2.14",
    "apollo-link-http": "^1.5.17",
    "awesome-phonenumber": "^6.8.0",
    "bowser": "^2.11.0",
    "date-fns": "^3.6.0",
    "filepond": "^4.31.1",
    "filepond-plugin-file-validate-size": "^2.2.8",
    "filepond-plugin-file-validate-type": "^1.2.9",
    "filepond-plugin-image-exif-orientation": "^1.0.11",
    "filepond-plugin-image-preview": "^4.6.12",
    "filepond-plugin-image-resize": "^2.0.10",
    "filepond-plugin-image-transform": "^3.8.7",
    "filepond-plugin-image-validate-size": "^1.2.7",
    "graphql": "^16.8.1",
    "graphql-tag": "^2.12.6",
    "is-plain-obj": "^4.1.0",
    "ng-zorro-antd": "^17.3.0",
    "ngx-cookie-service": "^17.1.0",
    "ngx-date-fns": "^11.0.0",
    "ngx-filepond": "^7.0.3",
    "ngx-validators": "^6.0.1",
    "reflect-metadata": "^0.2.1",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "typeserializer": "^0.2.5",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/architect": "^0.1702.3",
    "@angular-devkit/build-angular": "^17.2.3",
    "@angular-eslint/builder": "^17.2.1",
    "@angular-eslint/eslint-plugin": "^17.2.1",
    "@angular-eslint/eslint-plugin-template": "^17.2.1",
    "@angular-eslint/schematics": "^17.2.1",
    "@angular-eslint/template-parser": "^17.2.1",
    "@angular/cli": "^17.2.3",
    "@angular/compiler-cli": "^17.2.4",
    "@sentry/cli": "^2.31.2",
    "@types/google.maps": "^3.55.8",
    "@types/node": "^20.12.12",
    "@typescript-eslint/eslint-plugin": "^7.1.1",
    "@typescript-eslint/parser": "^7.1.1",
    "eslint": "^8.57.0",
    "eslint-import-resolver-typescript": "^3.6.1",
    "eslint-plugin-deprecation": "^2.0.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.1",
    "eslint-plugin-unicorn": "^51.0.1",
    "less": "^4.2.0",
    "postcss-scss": "^4.0.9",
    "prettier": "^3.2.5",
    "stylelint": "^16.4.0",
    "stylelint-order": "^6.0.4",
    "stylelint-prettier": "^5.0.0",
    "stylelint-scss": "^6.2.1",
    "ts-node": "^10.9.2",
    "typescript": "^5.4.5"
  }
}
