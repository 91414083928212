<div class="component-wrapper"  [class.loading]="actionLoading">
<div nz-row [nzGutter]="[48, 24]">

  <div nz-col nzSpan="24" class="invoiceStatus" *ngIf="invoice" [class]="invoice.status.toLowerCase()">
    <div class="invoiceStatusTitle">
      [ {{ invoice.status | invoiceStatusName }} ]
    </div>
    <div class="reason" *ngIf="invoice.statusReason">{{ invoice.statusReason }}</div>
    <div class="seeInvoice" *ngIf="invoice.pennylaneFileUrl"><a [href]="invoice.pennylaneFileUrl" target="_blank">Voir la facture Pennylane</a></div>
  </div>

</div>

<div nz-row [nzGutter]="[48, 24]">

  <div nz-col nzSpan="10" nzOffset="14" *ngIf="invoice">
    <div>Le {{ invoice.updatedDate  | dfnsFormat:('dd/MM/yy') }}</div>
    <div class="mt-16"><strong>{{ invoice.companyName }}</strong></div>
    <div>{{ invoice.address | nl2br }}</div>
    <div>{{ invoice.postalCode }}</div>
    <div>{{ invoice.city }}</div>
  </div>
</div>

<div nz-row [nzGutter]="[48, 0]">

  <div nz-col nzSpan="24">
    <span nz-typography>Référence de la facture : <strong>{{ invoice?.reference | nullCoal }}</strong></span>
  </div>
  <div nz-col nzSpan="24" *ngIf="invoice">
    <span nz-typography>Numéro de commande :
      <span *ngIf="!isOrderNumberEditable()"><strong>{{ invoice?.orderReference ? invoice?.orderReference : 'Aucun' }}</strong></span>

      <span *ngIf="isOrderNumberEditable() && !isOrderNumberEditing" (click)="editOrderNumber()" class="orderNumberEditableText"><strong>{{ invoice?.orderReference ? invoice?.orderReference : 'Aucun' }}</strong> &nbsp;<span nz-icon nzType="edit" nzTheme="outline" ></span></span>
      <span *ngIf="isOrderNumberEditable() && isOrderNumberEditing" laveoClickOutside (laveoClickOutside)="cancelEditing()" class="editOrderNumber">
        <input [(ngModel)]="orderNumberValue"  nz-input  placeholder="12345678" nzSize="large" maxlength="64" />
        <button [disabled]="isUpdating" nz-button nzType="primary"  (click)="saveOrderNumber($event)"   nzSize="large"><span nz-icon nzType="sync" nzSpin *ngIf="isUpdating"></span> Modifier</button>
      </span>
    </span>

  </div>

</div>
<div nz-row [nzGutter]="[48, 0]">

  <div nz-col nzSpan="24" class="mt-24">


    <nz-table [nzData]="['']" [nzFrontPagination]="false">
      <thead>
      <tr>
        <th nzColumnKey="articleReference">Référence</th>
        <th nzColumnKey="articleLabel">Libellé</th>
        <th nzColumnKey="priceUnitWithoutVat">Prix unitaire HT</th>
        <th nzColumnKey="quantity">Quantité</th>
        <th nzColumnKey="priceTotalWithoutVat">Prix total HT</th>
        <th nzColumnKey="priceTotal">Prix total TTC</th>
        <th nzColumnKey="actions"></th>
      </tr>
      </thead>
      <tbody>

      <ng-container *ngFor="let section of invoice?.invoiceRequestSection">
        <tr>
          <td colspan="7" class="sectionHeader">{{ section.name }}</td>
        </tr>
        <ng-container *ngFor="let detail of section?.invoiceRequestDetail">
          <tr [class]="detail.needValidation? 'detail-warning':''">

            <td>{{ detail.articleReference }}</td>
            <td>
              <div>{{ detail.articleLabel }}</div>
              <div><small>{{ detail.additionalDescription }}</small></div>
            </td>
            <td>{{ detail.priceUnitWithoutVat | currency: 'EUR' }}</td>
            <td>{{ detail.quantity }}</td>
            <td>{{ detail.priceTotalWithoutVat | currency: 'EUR' }}</td>
            <td>{{ detail.priceTotal  | currency: 'EUR' }}</td>

            <td class="actions">

              <div *ngIf="canDoActionOnInvoice(invoice)">

                <button nz-tooltip nzTooltipTitle="Supprimer la ligne" nz-button nzType="primary" class="ml-8"
                        (click)="removeInvoiceDetail(detail)" *ngIf="userCanDelete">
                  <i nz-icon nzType="delete"></i>
                </button>

              </div>
            </td>

          </tr>
        </ng-container>

        <tr class="last-line">

          <td colspan="4" class="total-title">Total</td>

          <td>{{ invoice?.totalAmountWithoutVat | currency: 'EUR' }}</td>
          <td>{{ invoice?.totalAmount  | currency: 'EUR' }}</td>

          <td class="actions">

          </td>

        </tr>
      </ng-container>
      </tbody>
    </nz-table>
  </div>

  <div nz-col nzSpan="24" class="mt-16 action-bottom"
       *ngIf="invoice?.status !== StatusInvoice.invoiced && invoice?.status !== StatusInvoice.paid">
    <button nz-button nzType="primary" nzSize="large" (click)="validate()">{{ getValidationText() }}</button>
  </div>

</div>


  <div *ngIf="actionLoading" class="overlay">
    <div class="spinner"></div>
    <div class="loading-text">Veuillez patienter...</div>


  </div>
</div>
