<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="24">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label>
              <ng-container *ngIf="customerSite?.logo; else logoTitle">Nouveau Logo</ng-container>
              <ng-template #logoTitle>Logo</ng-template>
            </nz-form-label>
            <nz-form-control>
              <file-pond [options]="fileUploadOptions" (onpreparefile)="addLogo($event)" (onremovefile)="removeLogo()"></file-pond>
            </nz-form-control>
            <span class="hint">Taille max. {{ this.fileUploadOptions.maxFileSize }}</span>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6" *ngIf="customerSite?.logo">
          <p>Logo actuel</p>
          <img class="current-logo" [src]="customerSite?.logo?.urlWithDomain" alt="Logo" />
        </div>
      </div>
    </div>

    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="customerSite">
          <nz-form-item>
            <nz-form-label nzFor="active">Actif</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name">Nom</nz-form-label>
            <nz-form-control [nzErrorTip]="nameError">
              <input formControlName="name" nz-input [nzSize]="'large'" placeholder="Nom"/>
            </nz-form-control>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control.hasError('required')">Le nom du client est requis</ng-container>
            </ng-template>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-col [nzXs]="24" *ngIf="googleMapsUtil.loaded | async">
      <nz-form-item>
        <nz-form-label nzFor="fullAddress">Adresse</nz-form-label>
        <nz-form-control [nzErrorTip]="fullAddressError">
          <input
            formControlName="fullAddress"
            nz-input
            nzSize="large"
            placeholder="Adresse"
            ngx-gp-autocomplete
            (onAddressChange)="onFullAddressChange($event)"
            [options]="placesAutocompleteOptions" />
        </nz-form-control>
        <ng-template #fullAddressError let-control>
          <ng-container *ngIf="control.hasError('required')">Une adresse complète est requise</ng-container>
        </ng-template>
      </nz-form-item>
    </div>
    <nz-form-item>
      <nz-form-label nzFor="phoneNumber">Téléphone</nz-form-label>
      <nz-form-control >
        <input
          formControlName="phoneNumber"
          nz-input
          nzSize="large"
          placeholder="0101010101"/>
      </nz-form-control>
    </nz-form-item>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-actor [control]="form.get('structure')" [typeEntity]="typeStructure"></laveo-select-actor>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-actor [control]="form.get('customer')" [typeEntity]="typeCustomer"></laveo-select-actor>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-customer-site-contract-type [control]="form.get('contractType')"></laveo-select-customer-site-contract-type>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <laveo-select-customer-site-workflow-type [control]="form.get('workflowType')"></laveo-select-customer-site-workflow-type>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <nz-form-item>
        <nz-form-label nzFor="frequencyServiceAffectedVehicle">Fréquence affecté</nz-form-label>
        <nz-form-control>
          <nz-input-group nzPrefix="Tous les" nzSuffix="jours" [nzSize]="'large'">
            <input type="number" formControlName="frequencyServiceAffectedVehicle" nz-input placeholder="Fréquence affecté"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24" [nzSm]="12">
      <nz-form-item>
        <nz-form-label nzFor="frequencyServiceMutualizedVehicle">Fréquence mutualisé</nz-form-label>
        <nz-form-control>
          <nz-input-group nzPrefix="Tous les" nzSuffix="jours" [nzSize]="'large'">
            <input type="number" formControlName="frequencyServiceMutualizedVehicle" nz-input placeholder="Fréquence mutualisé"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="12">
      <laveo-select-customer-site-billing-person [control]="form.get('billingPerson')"></laveo-select-customer-site-billing-person>
    </div>

    <div nz-col [nzXs]="3">
      <nz-form-item>
        <nz-form-label nzFor="shelteredSite">Site couvert</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="shelteredSite" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="3">
      <nz-form-item>
        <nz-form-label nzFor="electricityAccess">Accès à l'électricité</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="electricityAccess" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <nz-divider nzText="Permissions" nzOrientation="left"></nz-divider>
    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label>Permission formulaire</nz-form-label>
        <nz-form-control>
          <nz-tree
            [nzData]="permissions"
            nzCheckable
            nzMultiple
            [nzCheckedKeys]="checkedPermissions"
            (nzCheckBoxChange)="updatePermissions()">
          </nz-tree>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-divider nzText="Points de contrôle" nzOrientation="left"></nz-divider>
    <div nz-col [nzXs]="24">
      <nz-form-item>
        <nz-form-label>Points de contrôle</nz-form-label>
        <nz-form-control>
          <nz-tree
            [nzData]="vehicleCheckpoints"
            nzCheckable
            nzMultiple
            [nzCheckedKeys]="checkedVehicleCheckpoints"
            (nzCheckBoxChange)="updateVehicleCheckpoints()">
          </nz-tree>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-divider nzText="Contacts" nzOrientation="left"></nz-divider>
    <div nz-col [nzXs]="24">
      <laveo-inputs-contacts [controlArray]="contactFormArray" [showNotificationsCheckbox]="true"></laveo-inputs-contacts>
    </div>



    <nz-divider nzText="Facturation" nzOrientation="left"></nz-divider>

    <div nz-col  [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="billingEnabled">Facturation activée</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="billingEnabled" nzButtonStyle="solid" [nzSize]="'large'" [(ngModel)]="billingEnabled">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col  [nzXs]="24" [nzSm]="12" [hidden]="!(billingEnabled)">
      <nz-form-item>
        <nz-form-label nzFor="invoiceItemPrefix">Préfixe d'article pour la facturation</nz-form-label>
        <nz-form-control>
          <input
            formControlName="invoiceItemPrefix"
            nz-input
            nzSize="large"
            placeholder="UBE001"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="registrationId">SIREN</nz-form-label>
        <nz-form-control>
          <input
            formControlName="registrationId"
            nz-input
            nzSize="large"
            placeholder=""/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="vatNumber">N° TVA Intracommunautaire</nz-form-label>
        <nz-form-control>
          <input
            formControlName="vatNumber"
            nz-input
            nzSize="large"
            placeholder="FR44..."/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="billingAddress1">Adresse de facturation</nz-form-label>
        <nz-form-control >
          <input
            formControlName="billingAddress1"
            nz-input
            nzSize="large"
            placeholder="Adresse"/>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="billingAddress2">Complément d'adresse</nz-form-label>
        <nz-form-control>
          <input
            formControlName="billingAddress2"
            nz-input
            nzSize="large"
            placeholder=""/>
        </nz-form-control>
      </nz-form-item>

      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto">
          <nz-form-item>
            <nz-form-label nzFor="billingPostalCode">Code Postal</nz-form-label>
            <nz-form-control>
              <input
                formControlName="billingPostalCode"
                nz-input
                nzSize="large"
                placeholder="75001"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="billingCity">Ville</nz-form-label>
            <nz-form-control>
              <input
                formControlName="billingCity"
                nz-input
                nzSize="large"
                placeholder="Paris"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzFor="billingCountry">Pays</nz-form-label>
            <nz-form-control>
              <input
                formControlName="billingCountry"
                nz-input
                nzSize="large"
                placeholder="FR"/>
            </nz-form-control>
          </nz-form-item>
        </div>

      </div>


    </div>

    <div nz-col  [nzXs]="24" [nzSm]="12"  [hidden]="!(billingEnabled)">

      <nz-form-item>
        <nz-form-label nzFor="billingIsOrderNumberRequired">Besoin d'un PO / numéro d'ordre pour facturer</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="billingIsOrderNumberRequired" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="billingByVehicle">Facturation à l'immatriculation</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="billingByVehicle" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="billingToUploadOnTool">Facture à déposer sur un outil de gestion</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="billingToUploadOnTool" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="billingIsAgreementRequiredBeforeInvoicing">Envoi d'une demande de numéro d'accord avant facturation</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="billingIsAgreementRequiredBeforeInvoicing" nzButtonStyle="solid" [nzSize]="'large'">
            <label nz-radio-button [nzValue]="true">Oui</label>
            <label nz-radio-button [nzValue]="false">Non</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item  *ngIf="billingIsAgreementRequiredBeforeInvoicing">
        <nz-form-label nzFor="billingAgreementEmail">E-mail pour demande de l'accord avant facturation</nz-form-label>
        <nz-form-control>
          <input
            formControlName="billingAgreementEmail"
            nz-input
            nzSize="large"
            placeholder=""/>
        </nz-form-control>
      </nz-form-item>

    </div>



    <div nz-col  [nzXs]="24">
      <nz-form-item>
        <nz-form-label nzFor="billingText">Texte facturation</nz-form-label>
        <nz-form-control>
          <nz-textarea-count [nzMaxCharacterCount]="500">
            <textarea formControlName="billingText" nz-input placeholder="Texte facturation" nzSize="large" [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>




  </div>
</form>
