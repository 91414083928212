import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NzUploadFile, NzUploadXHRArgs} from "ng-zorro-antd/upload";
import {of, Subscription, throwError} from "rxjs";
import {ApolloError} from "@apollo/client/core";
import {ApiErrorMessageUtil} from "../../../../../shared/utils/api-error-message.util";
import {delay, switchMap} from "rxjs/operators";
import {UserService} from "../../../../../shared/services/api/user.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {Title} from "@angular/platform-browser";
import {UserRoleType} from "../../../../../shared/models/entities/user-role";
import {PricingService} from "../../../../../shared/services/api/pricing.service";
import {BatchProcessFailed} from "../../../../../shared/models/entities/batch-process-pricing";
import {NzModalService} from "ng-zorro-antd/modal";
import {ServicesService} from "../../../../../shared/services/api/services.service";
import {ProgressNotifierComponent} from "../../../shared/progress-notifier/progress-notifier.component";
import {HttpClient} from "@angular/common/http";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'laveo-admin-pricing',
  templateUrl: './admin-pricing.component.html',
  styleUrl: './admin-pricing.component.scss',
  providers: [DatePipe]
})
export class AdminPricingComponent implements OnInit, OnDestroy {

  @ViewChild('invoiceNotifier') invoiceNotifier: ProgressNotifierComponent;
  @ViewChild('importNotifier') importNotifier: ProgressNotifierComponent;
  @ViewChild('exportNotifier') exportNotifier: ProgressNotifierComponent;
  userIsAdmin = false;
  actionLoading = false;
  // Import
  importFileList: NzUploadFile[] = [];
  importErrorModalVisible = false;
  importErrors?: BatchProcessFailed[];

  excelPath =  '/excel/laveo-pricing-template.xlsx';


  //private currentRoleId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    //private readonly pricingService: PricingService,
    private readonly userService: UserService,
    private readonly pricingService: PricingService,
    private readonly message: NzMessageService,
    private readonly modal: NzModalService,
    private readonly servicesService: ServicesService,
    private readonly titleService: Title,
    private readonly datePipe: DatePipe,
    private http: HttpClient
  ) {}


  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  import = (item: NzUploadXHRArgs): Subscription => item.postFile as File ? this.pricingService.importPricings(item.postFile as File).subscribe({
    next: (response) => {
      if (response.data?.processOk?.length ?? 0 > 0) {
        this.message.success(`Import de ${response.data?.processOk?.length} tarifs réussi`);
      } else {
        this.message.warning(`Aucun tarif importé. Vérifiez votre fichier`);
      }

      if (response.data?.processFailed?.length ?? 0 > 0) {
        this.importErrorModalVisible = true;
        this.importErrors = response.data?.processFailed;
      }

      if (item.onSuccess) {
        item.onSuccess(null, item.file, null);
        this.importFileList = [];
      }
    },
    error: (error: ApolloError) => {
      console.error(error);
      this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  }) : of('').pipe(
    delay(500),
    switchMap(() => throwError(() => new Error('Erreur lors de l\'envoi. Réessayer')))
  ).subscribe({
    error: (error) => {
      if (item.onError) {
        item.onError(error, item.file);
      }
    }
  });

  handleChange(fileList: NzUploadFile[]): void {
    if (fileList?.length > 0) {
      let newFileList: NzUploadFile[] = [...fileList];
      newFileList = fileList.slice(-1);
      this.importFileList = newFileList;
    }
  }

  closeImportErrorModal(): void {
    this.importErrorModalVisible = false;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      //this.currentRoleId = role.actor.id;
      this.userIsAdmin = role.type === UserRoleType.admin;

    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Tarifs');
  }

  launchInvoicing() {
    // Facturer
    this.resetAllProgresses();
    this.modal.confirm({
      nzTitle: 'Facturer ?',
      nzContent: 'Êtes-vous sûr de vouloir générer les factures pour l\'ensemble des sites clients ?',
      nzOkText: 'Facturer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;

        this.servicesService.prepareAllInvoices().subscribe({
          next: response => {
            console.log("Response", response);
            this.actionLoading = false;
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
          }
        });


      }
    });
  }

  protected resetAllProgresses() {
    this.invoiceNotifier.reset();
    this.exportNotifier.reset();
    this.importNotifier.reset();
  }

  exportExcel() {
    this.resetAllProgresses();
    this.http.post(this.excelPath, {}, { responseType: 'blob' }).subscribe((response: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(response);
      a.href = objectUrl;
      a.download = "laveo_tarifs_"+ this.datePipe.transform(new Date(), 'yyyy-MM-dd-HH\'h\'mm')+ ".xlsx" ;
      a.style.display = 'none';
      document.body.append(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(objectUrl);
    });
  }
}
