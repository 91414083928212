import { Component, Input, OnInit } from '@angular/core';
import { Service, ServiceStatus } from '../../models/entities/service';
import { ServiceActions } from '../../models/entities/service-actions';
import { ServicesService } from '../../services/api/services.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from '../../services/api/user.service';
import { UserRight } from '../../models/entities/user-right';
import { Subscription } from 'rxjs';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';
import { CustomerSiteContractType } from '../../models/entities/customer-site-contract-type';

@Component({
  selector: 'laveo-row-service',
  templateUrl: './row-service.component.html',
  styleUrls: ['./row-service.component.scss']
})
export class RowServiceComponent implements OnInit {
  @Input() service: Service;
  @Input() canEdit = false;
  @Input() showClient = true;
  @Input() showStructure = true;
  @Input() checked: Service[] = [];
  showDot = false;
  actionLoading = false;
  userCanReadService = false;
  userCanUpdateService = false;
  userCanSeeInvoiced = false;
  userIsAdmin = false;

  availableActions: ServiceActions[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly servicesService: ServicesService,
    private readonly router: Router,
    private readonly modal: NzModalService,
    private readonly userService: UserService
  ) {}

  get isStatusLocked(): boolean {
    const status: ServiceStatus[] = [
      ServiceStatus.canceled_by_customer_site,
      ServiceStatus.canceled_by_structure,
      ServiceStatus.performed,
      ServiceStatus.vehicleNotPresent
    ];
    const current = this.service.status;
    if (current) {
      return status.includes(current);
    }
    return false;
  }

  public get contractIsTripartite(): boolean {
    return this.service?.vehicle.customerSite.contractType === CustomerSiteContractType.tripartite;
  }

  ngOnInit(): void {
    this.loadRole();
    this.setAvailableActions();
    this.showDot = this.service.hasActions;
  }

  performAction(action: ServiceActions): void {
    switch (action) {
      case ServiceActions.send_to_structure: {
        this.sendToStructure();
        break;
      }
      case ServiceActions.send_to_customer_site: {
        this.gotoDetail();
        break;
      }
      case ServiceActions.confirm: {
        this.confirm();
        break;
      }
      case ServiceActions.perform: {
        this.gotoDetail();
        break;
      }
      case ServiceActions.vehicle_not_present: {
        this.gotoDetail();
        break;
      }
    }
  }

  isServiceChecked(service?: Service | null): boolean {
    if (service) {
      return this.checked.map(s => s.id).includes(service.id);
    }
    return false;
  }

  setChecked(check: boolean, service?: Service | null): void {
    if (!service) {
      return;
    }
    const index = this.checked.map(s => s.id).indexOf(service.id);
    if (check && index === -1) {
      this.checked.push(service);
    } else if (!check && index > -1) {
      this.checked.splice(index, 1);
    }
  }

  isNotMidnight(date: Date): boolean {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return !(hours === 0 && minutes === 0);
  }

  toggleInvoiced() {
    this.servicesService.updateInvoicedService(this.service.id, !this.service.invoiced).subscribe(response => {
      if (response.data) {
        this.service.invoiced = response.data.invoiced;
      }
    });
  }
  refreshService() {
    this.servicesService.service(this.service.id).subscribe(response => {
      if (response.data) {
        this.service = response.data;
      }
    });
  }

  launchInvoice() {
    // Facturer
    this.modal.confirm({
      nzTitle: 'Facturer ?',
      nzContent: 'Êtes-vous sûr de vouloir générer la facture ?',
      nzOkText: 'Facturer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;

        this.servicesService.prepareInvoiceForSingleService(this.service.id).subscribe({
          next: response => {
            if (response.data) {
              // this.service = response.data;
              console.info(response.data);

              this.refreshService();
              // TODO process Invoice answer



              // Then reload service info
            }
            this.actionLoading = false;
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
          }
        });


      }
    });
  }

  private sendToStructure(): void {
    this.modal.confirm({
      nzTitle: 'Transmettre à la structure',
      nzContent: 'Êtes-vous sûr de vouloir transmettre la prestation à la structure ?',
      nzOkText: 'Transmettre',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        this.servicesService.sendToStructure(this.service.id).subscribe({
          next: response => {
            if (response.data) {
              this.service = response.data;
            }
            this.actionLoading = false;
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
          }
        });
      }
    });
  }

  private confirm(): void {
    this.modal.confirm({
      nzTitle: 'Confirmer',
      nzContent: 'Êtes-vous sûr de vouloir confirmer la prestation ?',
      nzOkText: 'Confirmer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        if (!this.service) {
          return;
        }

        this.actionLoading = true;
        this.servicesService.confirm(this.service?.id).subscribe({
          next: response => {
            if (response.data) {
              this.service = response.data;
            }
            this.actionLoading = false;
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
          }
        });
      }
    });
  }

  private gotoDetail(): void {
    void this.router.navigate(['/', 'prestations', this.service.id]);
  }

  private setAvailableActions(): void {
    this.subscriptions.push(this.userService.currentRole.subscribe(role => {
      this.availableActions = this.service.actions.filter(action => action !== ServiceActions.update && action !== ServiceActions.cancel && action !== ServiceActions.upload_vehicle_state);
      this.userCanUpdateService = this.service.actions.includes(ServiceActions.update);

        if (role.type === UserRoleType.structure) { // TODO faire mieux comme par exemple ajouter un rôle spécial
          this.availableActions = this.availableActions.filter(a => a !== ServiceActions.perform);
        }
    }));
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.userCanReadService = role.rights.services.includes(UserRight.read);
      this.userCanSeeInvoiced = role.type !== UserRoleType.preparer;
      this.userIsAdmin = role.type === UserRoleType.admin;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }
}
