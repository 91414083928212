<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      [title]="category | rightName:currentRole?.type"
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()">
    </laveo-card-header>
  </ng-template>


  <div *ngIf="isAllowed">
    <nz-tabset #tabset>
      <nz-tab [nzTitle]="invoiceTitle">
        <ng-template #invoiceTitle>
          <i nz-icon nzType="euro"></i>
          Factures
        </ng-template>
        <ng-template nz-tab>
          <laveo-invoice-list></laveo-invoice-list>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="manageTitle" *ngIf="isAdmin">
        <ng-template #manageTitle>
          <i nz-icon nzType="setting"></i>
          Gestion de la facturation
        </ng-template>
        <ng-template nz-tab>
          <laveo-admin-pricing></laveo-admin-pricing>
        </ng-template>
      </nz-tab>

    </nz-tabset>
  </div>
</nz-card>
