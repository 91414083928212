import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { ROUTES_CONFIG } from './configs/tokens.config';
import { RouteList } from './configs/routes.config';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { MockLinkProvider, apolloMock } from '../assets/mock/mock-link';
import { apolloProviders } from './shared/services/gql-integration';
import { HttpLink } from 'apollo-angular/http';

import { ZorroModule } from './shared/modules/zorro.module';
import { NZ_I18N, fr_FR, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ConfirmationMailModule } from './pages/confirmation-mail/confirmation-mail.module';
import { ForgotPasswordModule } from './pages/forgot-password/forgot-password.module';
import { LoginModule } from './pages/login/login.module';
import { LogoutModule } from './pages/logout/logout.module';
import { MainModule } from './pages/main/main.module';
import { NewAccountModule } from './pages/new-account/new-account.module';
import { ShortlinkModule } from './pages/shortlink/shortlink.module';

import { TimingInterceptor } from './shared/interceptors/timing.interceptor';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';

import { TraceService, createErrorHandler } from '@sentry/angular-ivy';

import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { setOptions } from 'filepond/dist/filepond.esm';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm';
import FilePondPluginImageResize from 'filepond-plugin-image-resize/dist/filepond-plugin-image-resize.esm';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation/dist/filepond-plugin-image-exif-orientation.esm';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size/dist/filepond-plugin-image-validate-size.esm';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform/dist/filepond-plugin-image-transform.esm';
import filePondLocale from 'filepond/locale/fr-fr.js';

import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { fr as frDate } from 'date-fns/locale';
import fr from '@angular/common/locales/fr';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { environment } from '../environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import {NzProgressModule} from "ng-zorro-antd/progress";

registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageTransform);
setOptions(filePondLocale);

const dateFnsConfig = new DateFnsConfigurationService();
dateFnsConfig.setLocale(frDate);
registerLocaleData(fr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ZorroModule,
    ApolloModule,
    LoginModule,
    LogoutModule,
    ForgotPasswordModule,
    NewAccountModule,
    ConfirmationMailModule,
    ShortlinkModule,
    MainModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DateFnsModule.forRoot(),
    FilePondModule,
    NgxGpAutocompleteModule,
    NzProgressModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: ROUTES_CONFIG, useValue: RouteList },
    { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    { provide: DateFnsConfigurationService, useValue: dateFnsConfig },
    { provide: MockLinkProvider, useFactory: apolloMock, deps: [HttpClient] },
    { provide: APOLLO_OPTIONS, useFactory: apolloProviders, deps: [HttpLink, MockLinkProvider] },
    { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
    { provide: TraceService, deps: [Router] },
    // eslint-disable-next-line unicorn/consistent-function-scoping, @typescript-eslint/no-empty-function
    { provide: APP_INITIALIZER, useFactory: () => (() => {}), deps: [TraceService], multi: true },
    { provide: Loader, useValue: new Loader({ apiKey: environment.googleMapsKey, libraries: ['places'] }) }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
