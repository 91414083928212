import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerSiteRequest } from '../../models/api/services/customer-site-requests.interface';
import { PagedRequest } from '../../models/api/shared/paged/paged-request';
import { PagedResponse } from '../../models/api/shared/paged/paged-response.interface';
import { CustomerSite, CustomerSiteArray } from '../../models/entities/customer-site';
import { TypeSerializerUtils } from '../../utils/type-serializer.util';

@Injectable({
  providedIn: 'root'
})
export class CustomerSitesService {
  constructor(
    private readonly apollo: Apollo
  ) {}

  addCustomerSite(data: CustomerSiteRequest): Observable<MutationResult<CustomerSite>> {
    const mutation = gql`
      mutation addCustomerSite($data: CustomerSiteRequest) {
        actors {
          customerSites {
            add (data: $data) {
              id
              active
              name
              address
              city
              postalCode
              country
              logo {
                id
                url
              }
              customer {
                id
                name
              }
              structure {
                id
                name
              }
              billingPerson
              billingText
              contractType
              shelteredSite
              electricityAccess
              frequencyServiceAffectedVehicle
              frequencyServiceMutualizedVehicle
              workflowType
              contacts {
                id
                name
                sendMail
                mail
                phoneNumber
                comment
              }
              type
              finishing
              firstIntervention
              vehicleVeryDirty
              upholsteryStainRemoval
              carpetStainRemoval
              conveying
              sticking
              desticking
              contactZoneDisinfection
              interiorDisinfection
              vehicleCheckpoints {
                id
                name
                category
              }

              phoneNumber
              billingEnabled
              billingAddress1
              billingAddress2
              billingPostalCode
              billingCity
              billingCountry
              billingEmail
              billingAgreementEmail
              billingByVehicle
              billingIsOrderNumberRequired
              billingToUploadOnTool
              billingIsAgreementRequiredBeforeInvoicing
              invoiceItemPrefix
              registrationId
              vatNumber
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ actors: { customerSites: { add: CustomerSite } } }>({
      mutation,
      variables: {
        data: {
          name: data?.name,
          active: data?.active,
          address: data?.address,
          city: data?.city,
          postalCode: data?.postalCode,
          country: data?.country,
          latitude: data?.latitude,
          longitude: data?.longitude,
          customerId: data?.customer?.id,
          structureId: data?.structure?.id,
          billingPerson: data?.billingPerson,
          billingText: data?.billingText,
          contractType: data?.contractType,
          shelteredSite: data?.shelteredSite,
          electricityAccess: data?.electricityAccess,
          frequencyServiceAffectedVehicle: data?.frequencyServiceAffectedVehicle,
          frequencyServiceMutualizedVehicle: data?.frequencyServiceMutualizedVehicle,
          workflowType: data?.workflowType,
          contacts : data.contacts,
          type : data.type,
          finishing : data.finishing ,
          firstIntervention : data.firstIntervention,
          vehicleVeryDirty : data.vehicleVeryDirty,
          upholsteryStainRemoval : data.upholsteryStainRemoval,
          carpetStainRemoval : data.carpetStainRemoval,
          conveying : data.conveying,
          sticking : data.sticking,
          desticking : data.desticking,
          contactZoneDisinfection : data.contactZoneDisinfection,
          interiorDisinfection : data.interiorDisinfection,
          logo: data.logo,
          vehicleCheckpointsId: data.vehicleCheckpointsId,


          // BILLING
          phoneNumber: data?.phoneNumber,
          billingEnabled: data?.billingEnabled,
          billingAddress1: data?.billingAddress1,
          billingAddress2: data?.billingAddress2,
          billingPostalCode: data?.billingPostalCode,
          billingCity: data?.billingCity,
          billingCountry: data?.billingCountry,
          billingEmail: data?.billingEmail,
          billingAgreementEmail: data?.billingAgreementEmail,
          billingByVehicle: data?.billingByVehicle,
          billingIsOrderNumberRequired: data?.billingIsOrderNumberRequired,
          billingToUploadOnTool: data?.billingToUploadOnTool,
          billingIsAgreementRequiredBeforeInvoicing: data?.billingIsAgreementRequiredBeforeInvoicing,
          invoiceItemPrefix: data?.invoiceItemPrefix,
          registrationId: data?.registrationId,
          vatNumber: data?.vatNumber,

        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      map(result => ({

        data: TypeSerializerUtils.deserialize(result.data?.actors?.customerSites?.add, CustomerSite),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      }))
    );
  }

  updateCustomerSite(data: CustomerSiteRequest): Observable<MutationResult<CustomerSite>> {
    const mutation = gql`
      mutation updateCustomerSite($id: Guid!, $data: CustomerSiteRequest) {
        actors {
          customerSites {
            update (id: $id, data: $data) {
              id
              active
              name
              address
              city
              postalCode
              country
              logo {
                id
                url
              }
              customer {
                id
                name
              }
              structure {
                id
                name
              }
              billingPerson
              billingText
              contractType
              shelteredSite
              electricityAccess
              frequencyServiceAffectedVehicle
              frequencyServiceMutualizedVehicle
              workflowType
              contacts {
                id
                name
                sendMail
                mail
                phoneNumber
                comment
              }
              type
              finishing
              firstIntervention
              vehicleVeryDirty
              upholsteryStainRemoval
              carpetStainRemoval
              conveying
              sticking
              desticking
              contactZoneDisinfection
              interiorDisinfection
              vehicleCheckpoints {
                id
                name
                category
              }

              phoneNumber
              billingEnabled
              billingAddress1
              billingAddress2
              billingPostalCode
              billingCity
              billingCountry
              billingEmail
              billingAgreementEmail
              billingByVehicle
              billingIsOrderNumberRequired
              billingToUploadOnTool
              billingIsAgreementRequiredBeforeInvoicing
              invoiceItemPrefix
              registrationId
              vatNumber
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ actors: { customerSites: { update: CustomerSite } } }>({
      mutation,
      variables: {
        id: data.id,
        data: {
          name: data?.name,
          active: data?.active,
          address: data?.address,
          city: data?.city,
          postalCode: data?.postalCode,
          country: data?.country,
          latitude: data?.latitude,
          longitude: data?.longitude,
          customerId: data?.customer?.id,
          structureId: data?.structure?.id,
          billingPerson: data?.billingPerson,
          billingText: data?.billingText,
          contractType: data?.contractType,
          shelteredSite: data?.shelteredSite,
          electricityAccess: data?.electricityAccess,
          frequencyServiceAffectedVehicle: data?.frequencyServiceAffectedVehicle,
          frequencyServiceMutualizedVehicle: data?.frequencyServiceMutualizedVehicle,
          workflowType: data?.workflowType,
          contacts : data.contacts,
          type : data.type,
          finishing : data.finishing ,
          firstIntervention : data.firstIntervention,
          vehicleVeryDirty : data.vehicleVeryDirty,
          upholsteryStainRemoval : data.upholsteryStainRemoval,
          carpetStainRemoval : data.carpetStainRemoval,
          conveying : data.conveying,
          sticking : data.sticking,
          desticking : data.desticking,
          contactZoneDisinfection : data.contactZoneDisinfection,
          interiorDisinfection : data.interiorDisinfection,
          logo: data.logo,
          vehicleCheckpointsId: data.vehicleCheckpointsId,

          // BILLING
          phoneNumber: data?.phoneNumber,
          billingEnabled: data?.billingEnabled,
          billingAddress1: data?.billingAddress1,
          billingAddress2: data?.billingAddress2,
          billingPostalCode: data?.billingPostalCode,
          billingCity: data?.billingCity,
          billingCountry: data?.billingCountry,
          billingEmail: data?.billingEmail,
          billingAgreementEmail: data?.billingAgreementEmail,
          billingByVehicle: data?.billingByVehicle,
          billingIsOrderNumberRequired: data?.billingIsOrderNumberRequired,
          billingToUploadOnTool: data?.billingToUploadOnTool,
          billingIsAgreementRequiredBeforeInvoicing: data?.billingIsAgreementRequiredBeforeInvoicing,
          invoiceItemPrefix: data?.invoiceItemPrefix,
          registrationId: data?.registrationId,
          vatNumber: data?.vatNumber,

        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(
      map(result => ({

        data: TypeSerializerUtils.deserialize(result.data?.actors?.customerSites?.update, CustomerSite),
        errors: result.errors,
        extensions: result.extensions,
        loading: result.loading
      }))
    );
  }

  deleteCustomerSite(...ids: string[]): Observable<MutationResult<void>> {
    const query = `
      mutation deleteCustomerSite(` + ids.map((_, index) => '$id' + index + ': Guid!').join(', ') + `) {
        actors {
          customerSites {\n` + ids.map((_, index) => 'id' + index + ': delete (id: $id' + index + ')').join('\n') + `\n}
        }
      }
    `;

    const variables = ids.reduce((response, item, index) => {
      response['id' + index] = item;
      return response;
    }, {});

    return this.apollo.mutate<void>({
      mutation: gql(query),
      variables
    });
  }

  deleteOneCustomerSiteHard(id: string): Observable<MutationResult<void>> {
    const mutation = gql`
      mutation deleteCustomerSiteActor($id: Guid!, $hard: Boolean) {
        actors {
          customerSites {
            delete(id: $id, hard: $hard)
          }
        }
      }
    `;

    const variables = {
      id,
      hard: true
    };

    return this.apollo.mutate<void>({
      mutation,
      variables
    });
  }

  customerSite(id: string): Observable<ApolloQueryResult<CustomerSite>> {
    const query = gql`
      query customerSite($id: Guid!) {
        actors {
          customerSite(id: $id){
            id
            active
            name
            address
            city
            postalCode
            country
            logo {
              id
              url
            }
            customer {
              id
              name
            }
            structure {
              id
              name
            }
            billingPerson
            billingText
            contractType
            shelteredSite
            electricityAccess
            frequencyServiceAffectedVehicle
            frequencyServiceMutualizedVehicle
            workflowType
            contacts {
              id
              name
              sendMail
              mail
              phoneNumber
              comment
            }
            type
            finishing
            firstIntervention
            vehicleVeryDirty
            upholsteryStainRemoval
            carpetStainRemoval
            conveying
            sticking
            desticking
            contactZoneDisinfection
            interiorDisinfection
            vehicleCheckpoints {
              id
              name
              category
            }

            phoneNumber
            billingEnabled
            billingAddress1
            billingAddress2
            billingPostalCode
            billingCity
            billingCountry
            billingEmail
            billingAgreementEmail
            billingByVehicle
            billingIsOrderNumberRequired
            billingToUploadOnTool
            billingIsAgreementRequiredBeforeInvoicing
            invoiceItemPrefix
            registrationId
            vatNumber
          }
        }
      }
    `;

    return this.apollo.query<{ actors: { customerSite: CustomerSite } }>({
      query,
      variables: { id }
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.actors?.customerSite, CustomerSite),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }

  allCustomerSites(parameters: PagedRequest): Observable<ApolloQueryResult<PagedResponse<CustomerSite>>> {
    const query = gql`
      query customerSites($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $search: String) {
        actors {
          customerSites(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              active
              name
              address
              city
              postalCode
              country
              workflowType
              logo {
                id
                url
              }
              customer {
                id
                name
              }
              structure {
                id
                name
              }
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ actors: { customerSites: PagedResponse<CustomerSite> } }>({
      query,
      variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        search: parameters.search
      }
    }).pipe(map(result => {
      const raw = result.data?.actors?.customerSites;
      const response: PagedResponse<CustomerSite> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, CustomerSiteArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }
}
