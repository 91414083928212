<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <div class="header">

      <nz-upload  [(nzFileList)]="importFileList" (nzFileListChange)="handleChange($event)" [nzCustomRequest]="import" [nzAccept]="'.xlsx,.xls'" *ngIf="this.userIsAdmin">
        <button
          nz-button
          nzType="primary"
          nzSize="large" (click)="resetAllProgresses()"
        ><i nz-icon nzType="upload"></i>Importer des tarifs (.xlsx)</button>
      </nz-upload>
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="exportExcel()"
        class="ml-8"
        *ngIf="this.userIsAdmin"><i nz-icon nzType="download"></i>Télécharger les tarifs actuels</button>


      <div>
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="launchInvoicing()"
        target="_blank"
        class="ml-8"
        *ngIf="this.userIsAdmin"><i nz-icon [nzType]="!actionLoading ? 'file-sync' : 'loading'"></i>Générer les factures</button>
      </div>


      <nz-modal
        [(nzVisible)]="importErrorModalVisible"
        (nzOnOk)="closeImportErrorModal()"
        (nzOnCancel)="closeImportErrorModal()"
        nzTitle="Erreur d'import pour {{ importErrors?.length }} tarifs">
        <ng-container *nzModalContent>
          <ul>
            <li *ngFor="let error of importErrors!"><b>{{ error.object.customerSite.name }}</b> : {{ error.message }}</li>
          </ul>
        </ng-container>
      </nz-modal>



    </div>

    <div class="import-detail-zone">

      <laveo-progress-notifier #invoiceNotifier title="Détail de la génération des factures" queue="InvoicingProgress" />
      <laveo-progress-notifier #importNotifier title="Détail de l'import Excel"  queue="ExcelImportProgress"/>
      <laveo-progress-notifier #exportNotifier title="Détail de l'export Excel"  queue="ExcelExportProgress"/>
    </div>
  </ng-template>
</nz-card>
