
<div nz-row class="mb-16">
  <div>{{ description }}</div>
</div>

<div nz-row>
  <form nz-form [formGroup]="searchForm">
    <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
      <div nz-col *ngIf="checked.length > 0 && userCanDelete">
        <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu"
                [disabled]="actionLoading">
          <i *ngIf="actionLoading" nz-icon nzType="loading" class="loading-icon"></i>
          {{ checked.length }} {{ checked.length > 1 ? 'factures sélectionnées' : 'facture sélectionnée' }}
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #actionMenu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <!-- <li nz-menu-item (click)="copyChecked()">
              <i nz-icon nzType="copy"></i>
              Dupliquer
            </li> -->
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="checked.splice(0, checked.length)">
              <i nz-icon nzType="minus-square"></i>
              Tout déséléctionner
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div nz-col nzFlex="1 1 auto">
        <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
          <ng-template #prefixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
          <input type="text" nz-input placeholder="Rechercher une facture" formControlName="search"/>
        </nz-input-group>
      </div>
    </div>
  </form>


  <nz-table nzShowSizeChanger
            [nzData]="datas ? datas.data : []"
            [nzFrontPagination]="false"
            [nzLoading]="isLoading"
            [nzTotal]="datas ? datas.metadata.totalResults : 0"
            [nzPageSize]="limit"
            [nzPageIndex]="datas ? datas.metadata.currentPage : 0"
            [nzNoResult]="emptyTemplate"
            (nzQueryParams)="setPage($event)"
            *ngIf="!error; else errorTemplate">
    <thead>
    <tr>
      <th nzWidth="60px" *ngIf="userCanDelete"></th>
      <th nzColumnKey="updatedDate" [nzSortFn]="true">Date de modification</th>
      <th nzColumnKey="reference">Reference</th>
      <th nzColumnKey="customerSite" [nzSortFn]="true" *ngIf="userCanReadClients">Site Client</th>
      <th nzColumnKey="customer" [nzSortFn]="true" *ngIf="userCanReadClients">Client</th>
      <th nzColumnKey="totalAmountWithoutVAT" [nzSortFn]="true">Total HT</th>
      <th nzColumnKey="totalAmount">Total TTC</th>
      <th nzColumnKey="status">Statut</th>
      <th nzColumnKey="actions" class="actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let invoiceRequest of datas?.data">
      <tr>
        <td [nzChecked]="checked.includes(invoiceRequest.id)" (nzCheckedChange)="setChecked($event, invoiceRequest.id)"
            *ngIf="userCanDelete"></td>
        <td>{{ invoiceRequest.updatedDate  | dfnsFormat:('dd/MM/yy HH\'h\'mm') }}</td>
        <td>{{ invoiceRequest.reference }}</td>
        <td *ngIf="userCanReadClients">
          <div *ngIf="invoiceRequest.customerSite">
            <button nz-button
                    (click)="goToCustomerSite(invoiceRequest.customerSite)">{{ invoiceRequest.customerSite.name }}<i
              nz-icon nzType="right-square" nzTheme="outline"></i></button>
          </div>
        </td>
        <td *ngIf="userCanReadClients">
          <button nz-button (click)="goToCustomer(invoiceRequest.customer)">{{ invoiceRequest.customer.name }}<i nz-icon
                                                                                                                 nzType="right-square"
                                                                                                                 nzTheme="outline"></i>
          </button>
        </td>
        <td>{{ invoiceRequest.totalAmountWithoutVat | currency: 'EUR' }}</td>
        <td>{{ invoiceRequest.totalAmount | currency: 'EUR' }}</td>
        <td>

          <div nz-col class="statut">
            <nz-tag
              nzColor="{{ invoiceRequest.status | invoiceStatusColor }}"
              class="w-100-p"
              nz-tooltip
              [nzTooltipTitle]="invoiceRequest.status | invoiceStatusName">
              {{ invoiceRequest.status | invoiceStatusName:true }}
            </nz-tag>
          </div>

        </td>
        <td class="actions">
          <button nz-button class="ml-8" (click)="openPdf(invoiceRequest)" *ngIf="invoiceRequest.pennylaneFileUrl" >
            <i nz-icon nzType="file-pdf"></i>
          </button>
          <button nz-button class="ml-8" [routerLink]="['/', 'invoice', invoiceRequest.id]">
            <i nz-icon [nzType]="isEditable(invoiceRequest)?'edit':'eye'"></i>
          </button>
          <button nz-button class="ml-8" nzType="primary" (click)="delete(invoiceRequest)" *ngIf="isDeletable(invoiceRequest)">
            <i nz-icon nzType="delete"></i>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </nz-table>


  <ng-template #errorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
               nzSubTitle="Une erreur est survenue lors du chargement de la liste des sites client">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
  <ng-template #emptyTemplate>
    <nz-empty
      nzNotFoundImage="simple"
      nzNotFoundContent="Aucune structure trouvée. Commencez par en ajouter un en utilisant le bouton ci-dessous."
      [nzNotFoundFooter]="emptyFooter">
      <ng-template #emptyFooter>

        <div></div>
      </ng-template>
    </nz-empty>
  </ng-template>
</div>
