import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Apollo, gql, MutationResult} from 'apollo-angular';
import {map} from "rxjs/operators";
import {TypeSerializerUtils} from "../../utils/type-serializer.util";
import {ApolloQueryResult} from "@apollo/client/core";
import {PagedRequest} from "../../models/api/shared/paged/paged-request";
import {PagedResponse} from "../../models/api/shared/paged/paged-response.interface";
import {InvoiceRequest, InvoiceRequestArray} from "../../models/entities/invoice-request";
import {InvoiceRequestRequest} from "../../models/api/services/invoice-request-request.interface";

@Injectable({
  providedIn: 'root'
})
export class InvoiceRequestsService {
  constructor(
    private readonly apollo: Apollo
  ) {}


  delete(id: string): Observable<MutationResult<boolean>> {
    const mutation = gql`
      mutation deleteInvoiceRequest($id: Guid!) {
        invoiceRequests {
          delete(id: $id)
        }
      }
    `;

    const variables = {
      id
    };

    return this.apollo.mutate<boolean>({
      mutation,
      variables
    });
  }

  invoiceRequest(id: string): Observable<ApolloQueryResult<InvoiceRequest>> {
    const query = gql`
      query invoiceRequest($id: Guid!){
        invoiceRequests  {
          invoiceRequest(id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.query<{ invoiceRequests: { invoiceRequest: InvoiceRequest } }>({
      query,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests?.invoiceRequest, InvoiceRequest),
      error: result.error,
      errors: result.errors,
      partial: result.partial,
      loading: result.loading,
      networkStatus: result.networkStatus
    })));
  }


  allInvoices(parameters: PagedRequest): Observable<ApolloQueryResult<PagedResponse<InvoiceRequest>>> {
    const query = gql`
      query invoiceRequests($offset: Int, $limit: Int, $sortProperty: String, $sortType: ESortType, $search: String) {
        invoiceRequests {
          invoiceRequests(offset: $offset, limit: $limit, sortProperty: $sortProperty, sortType: $sortType, search: $search) {
            data {
              id
              customerSite {
                name
                id

              }
              customer {
                name
                id
              }
              updatedDate
              createdDate
              totalAmountWithoutVat
              totalAmount
              vatAmount
              reference
              orderReference
              companyName
              address
              postalCode
              city
              country
              status
              statusReason
              pennylaneFileUrl
              pennylaneInvoiceNumber
              pennylanePublicUrl
            }
            metadata {
              currentPage
              currentResult
              totalPages
              totalResults
              hasMore
            }
          }
        }
      }
    `;
    return this.apollo.query<{ invoiceRequests: { invoiceRequests: PagedResponse<InvoiceRequest> } }>({
      query, variables: {
        offset: parameters.offset,
        limit: parameters.limit,
        sortProperty: parameters.sortProperty,
        sortType: parameters.sortType,
        filters: parameters.filters,
        search: parameters.search
      },
      fetchPolicy: 'network-only'
    }).pipe(map(result => {
      const raw = result.data?.invoiceRequests?.invoiceRequests;
      const response: PagedResponse<InvoiceRequest> = {
        data: TypeSerializerUtils.deserializeArr(raw.data, InvoiceRequestArray),
        metadata: raw.metadata
      };
      return {
        data: response,
        error: result.error,
        errors: result.errors,
        partial: result.partial,
        loading: result.loading,
        networkStatus: result.networkStatus
      };
    }));
  }

  updateInvoice(data: InvoiceRequestRequest): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation updateInvoice($id: Guid!, $data: InvoiceRequestRequest) {
        invoiceRequests {

          update(id: $id, data: $data) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;

    return this.apollo.mutate<{ invoiceRequests: { update: InvoiceRequest } }>({
      mutation,
      variables: {
        id: data.id,
        data: {
          reference: data.reference,
          orderReference: data.orderReference
        }
      },
      context: {
        useMultipart: true
      }
    }).pipe(map(result => ({

      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests?.update, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }


  deleteInvoiceDetail(id: string): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        invoiceRequests {
          removeDetail (id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.mutate<{ invoiceRequests: { removeDetail: InvoiceRequest } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests.removeDetail, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

  validateInvoice(id: string): Observable<MutationResult<InvoiceRequest>> {
    const mutation = gql`
      mutation ($id: Guid!) {
        invoiceRequests {
          validate (id: $id) {
            id
            customerSite {
              name
              id

            }
            customer {
              name
              id
            }
            updatedDate
            createdDate
            totalAmountWithoutVat
            totalAmount
            invoiceRequestSection {
              id
              name
              rank

              invoiceRequestDetail {
                id
                articleReference
                articleLabel
                additionalDescription
                needValidation
                priceUnitTotal
                priceUnitWithoutVat
                priceTotalWithoutVat
                priceTotal
                serviceId
                vat
                quantity
                vatUnitAmount
                vatTotalAmount
                licensePlate
              }
            }
            vatAmount
            reference
            orderReference
            companyName
            address
            postalCode
            city
            country
            status
            statusReason
            pennylaneFileUrl
            pennylaneInvoiceNumber
            pennylanePublicUrl
          }
        }
      }
    `;
    return this.apollo.mutate<{ invoiceRequests: { validate: InvoiceRequest } }>({
      mutation,
      variables: {id}
    }).pipe(map(result => ({
      data: TypeSerializerUtils.deserialize(result.data?.invoiceRequests.validate, InvoiceRequest),
      errors: result.errors,
      extensions: result.extensions,
      loading: result.loading
    })));
  }

}
