import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NzTableModule} from 'ng-zorro-antd/table';
import {MainSharedModule} from '../../shared/main-shared.module';
import {SharedModule} from '../../../../shared/shared.module';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzDividerComponent} from "ng-zorro-antd/divider";
import {NzInputNumberComponent} from "ng-zorro-antd/input-number";
import {AdminInvoicesComponent} from "./admin-invoices.component";
import {FormatPipeModule} from "ngx-date-fns";
import {InvoiceComponent} from "./invoices/invoice.component";
import {InvoiceDetailComponent} from "./invoices/invoice-detail/invoice-detail.component";
import {InvoiceListComponent} from "./invoices/invoice-list/invoice-list.component";
import {NzTabComponent, NzTabDirective, NzTabSetComponent} from "ng-zorro-antd/tabs";
import {AdminPricingComponent} from "./admin-pricing/admin-pricing.component";
import {NzUploadComponent} from "ng-zorro-antd/upload";
import {NzModalComponent} from "ng-zorro-antd/modal";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {NzTagComponent} from "ng-zorro-antd/tag";
import {NzSpinComponent} from "ng-zorro-antd/spin";

@NgModule({
  declarations: [
    AdminInvoicesComponent,
    InvoiceComponent,
    InvoiceDetailComponent,
    InvoiceListComponent,
    AdminPricingComponent
  ],
  imports: [
    CommonModule,
    MainSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzIconModule,
    NzCardModule,
    NzFormModule,
    NzTableModule,
    NzInputModule,
    NzResultModule,
    NzEmptyModule,
    NzRadioModule,
    NzTreeModule,
    NzDropDownModule,
    NzDividerComponent,
    NzInputNumberComponent,
    FormatPipeModule,
    NzTabComponent,
    NzTabDirective,
    NzTabSetComponent,
    NzUploadComponent,
    NzModalComponent,
    NzTooltipDirective,
    NzTagComponent,
    NzSpinComponent
  ],
  exports: [
    AdminInvoicesComponent,
    AdminPricingComponent
  ]
})
export class AdminInvoicesModule {}
